
import { defineComponent, computed } from 'vue'
import StepContainer from '@/components/LdStepper/StepContainer/StepContainer.vue'
import { SUPPORT_EMAIL, GA_URL } from '@/lib/constants'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import LdButton from '@/components/LdButton/LdButton.vue'
import { format, differenceInDays } from 'date-fns'
import { PaymentStatus } from '@/types/payment'
import { sendBehaviorEvent, sendPageViewEvent, AppVirtualUrls } from '@/services/segmentTracking'

export default defineComponent({
	name: 'Success',
	components: {
		StepContainer,
		LdButton,
	},
	setup() {
		const { tm, t } = useI18n()
		const store = useStore()
		// retrieve the benefits locale array using the tm function.
		// the tm function used togher with the rt function may could
		// be useful to loop over a locale array in the template
		// @ts-ignore
		const benefits = tm('connection.steps.success.benefits')
		const { value: paymentData } = computed(() => store.getters['payments/payment/getState'])
		const { status, product, subExpiry } = paymentData?.data
		const activePlan = {
			name: product?.name,
			amount: product?.prices[0]?.amount,
			recurring: product?.prices[0]?.recurring,
			status,
			startingDate: format(new Date(subExpiry), 'MMMM dd, yyyy'),
			daysLeft: differenceInDays(new Date(subExpiry), new Date()),
		}

		const sendEmail = () => {
			sendBehaviorEvent({
				locationTitle: t('connection.steps.success.title'),
				virtualUrl: AppVirtualUrls.ga3ThankYou,
				fieldTitle: '',
				actionId: '',
				actionValue: SUPPORT_EMAIL,
				actionType: 'link',
			})

			window.location.href = `mailto:${SUPPORT_EMAIL}?subject=${t(
				'connection.steps.success.emailSubject'
			)}`
		}

		const launchGA = (e: Event) => {
			const target = e.target as HTMLElement

			sendBehaviorEvent({
				locationTitle: t('connection.steps.success.title'),
				virtualUrl: 'ga3-thank-you',
				fieldTitle: '',
				actionId: '',
				actionValue: target.innerText,
				actionType: target.tagName,
			})

			window.open(GA_URL)
		}

		return {
			SUPPORT_EMAIL,
			GA_URL,
			benefits,
			activePlan,
			isOnTrial: activePlan.status === PaymentStatus.TRIALING,
			sendEmail,
			launchGA,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('connection.steps.success.title'),
			virtualUrl: 'ga3-thank-you',
		})
	},
})
